<template>
    <div class="main-wrapper">
        <div style="text-align: right">
            <el-button type="primary" @click="addBtn"><i class="el-icon-plus"></i> 添加商品</el-button>
        </div>
        <el-table :data="goodsList" class="customTable" style="width: 100%; margin-top: 20px; flex: 1" height="1%">
            <el-table-column label="商品信息" min-width="150">
                <template slot-scope="scope">
                    <div class="goods-wrapper">
                        <div class="goods-cover">
                            <img :src="scope.row.good_images" alt="">
                        </div>
                        <div class="text-overflow-2 goods-name" :title="scope.row.good_title">{{scope.row.good_title}}</div>
                    </div>
                </template>
            </el-table-column>
            <el-table-column prop="good_sku" label="SKU" align="center"></el-table-column>
            <el-table-column prop="market_price" label="市场价" align="center"></el-table-column>
            <el-table-column prop="sales_price" label="销售价" align="center"></el-table-column>
            <el-table-column label="操作" align="center">
                <template slot-scope="scope">
                    <el-link type="success" :underline="false" @click="editBtn(scope.row.id)">编辑</el-link>
                    <el-link type="danger" :underline="false" @click="delBtn(scope.row.id)">删除</el-link>
                </template>
            </el-table-column>
        </el-table>
        <el-pagination class="pages-center" style="margin: 20px 0 30px"
                       :current-page="listPages.currentPageNum"
                       :page-size="listPages.eachPageNum"
                       layout="prev, pager, next, jumper"
                       :total="listPages.total"
                       @current-change="pagesCurrentChange">
        </el-pagination>
    </div>
</template>

<script>
    import { adminGoodListGood, adminGoodDelGood } from '@/utils/apis.js'
    export default {
        data() {
            return {
                goodsList: [],
                listPages: {
                    currentPageNum: 1,
                    eachPageNum: 10,
                    total: 0
                },
            }
        },
        mounted() {
            this.getList()
        },
        methods: {
            getList() {
                let params = {
                    type: 'page',
                    page: this.listPages.currentPageNum,
                    limit: this.listPages.eachPageNum,
                }
                adminGoodListGood(params).then((res) => {
                    this.goodsList = res.data.data
                    this.listPages.total = res.data.total
                })
            },
            addBtn() {
                this.$router.push('/practice/goodsCategory')
            },
            pagesCurrentChange(val) {
                this.listPages.currentPageNum = val
                this.getList()
            },
            editBtn(id) {
                this.$router.push({
                    path: '/practice/goodsCategory',
                    query: {
                        id: id
                    }
                })
            },
            delBtn(id) {
                this.$confirm('是否删除该商品，删除完将无法恢复', '提示消息', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    customClass: 'green-message-box',
                    type: 'warning',
                }).then(() => {
                    let params = {
                        id: id
                    }
                    adminGoodDelGood(params).then((res) => {
                        this.$message.success(res.msg)
                        this.getList()
                    })
                }).catch(() => {
                    this.$message.info('已取消删除');
                })
            }
        }
    }
</script>

<style scoped lang="scss">
    .main-wrapper {
        height: 100%;
        padding: 0 20px;
        display: flex;
        flex-direction: column;
    }
    .goods-wrapper {
        display: flex;
        align-items: center;
        .goods-cover {
            width: 80px;
            height: 80px;
            display: flex;
            background: #F6F6F6;
            img  {
                max-width: 100%;
                max-height: 100%;
                margin: auto;
            }
        }
        .goods-name {
            width: 1%;
            flex: 1;
            margin-left: 10px;
            font-size: 16px;
        }
    }
</style>